<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.truck_tord_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="上游编号">
				<el-input class="el_input" v-model="form.out_trade_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="组别编号">
				<el-input class="el_input" v-model="form.group1_num" placeholder="运单组别编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货主手机">
				<el-input class="el_input" v-model="form.shipper_tel" placeholder="货主手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货主姓名">
				<el-input class="el_input" v-model="form.shipper_user_name" placeholder="货主姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货主公司">
				<el-input class="el_input" v-model="form.shipper_company_name" placeholder="货主公司名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="收款人手机">
				<el-input class="el_input" v-model="form.truck_owner_tel" placeholder="收款人手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="收款人姓名">
				<el-input class="el_input" v-model="form.truck_owner_name" placeholder="收款人姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机手机">
				<el-input class="el_input" v-model="form.driver_tel" placeholder="司机手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机姓名">
				<el-input class="el_input" v-model="form.driver_name" placeholder="司机姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="车牌号">
				<el-input class="el_input" v-model="form.truck_plate_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="上报状态">
				<el-select class="el_input" v-model="form.upl_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未上报" value="1"></el-option>
					<el-option label="运单上报成功" value="2"></el-option>
					<el-option label="运单上报失败" value="3"></el-option>
					<el-option label="流水上报成功" value="4"></el-option>
					<el-option label="流水上报失败" value="5"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="上报目标">
				<el-select class="el_input" v-model="form.upl_aim" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option 
						v-for="(cname,ename) of logink_aim_list" 
						:key="ename" :label="cname" :value="ename"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="结算方式">
				<el-select class="el_input" v-model="form.settlement_type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="按吨数" value="1"></el-option>
					<el-option label="按方数" value="2"></el-option>
					<el-option label="按趟" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="是否回程">
				<el-select class="el_input" v-model="form.is_need_return" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="需要" value="1"></el-option>
					<el-option label="不需要" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="添加网点">
				<el-select class="el_input" v-model="form.outlets_num" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option v-for="(item,index) in ser_para.outlets_list" :key="index" :label="item.name" :value="item.outlets_num"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="运单编号/组别编号/上游单号" width="210px">
					<template slot-scope="scope">
						<div class="tab_line_item">编号:{{scope.row.truck_tord_num}}</div>
						<div class="tab_line_item">组别:{{scope.row.group1_num?scope.row.group1_num:"无"}}</div>
						<div class="tab_line_item">上游:{{scope.row.out_trade_num?scope.row.out_trade_num:"无"}}</div>
						<div class="tab_line_item">结束:{{scope.row.tord_end_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="装卸货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">发货地:{{scope.row.cargo_info.case_prov}}/{{scope.row.cargo_info.case_city}}/{{scope.row.cargo_info.case_county}}/{{scope.row.cargo_info.case_other.addr_info?scope.row.cargo_info.case_other.addr_info:'无'}}</div>
						<div class="tab_line_item">卸货地:{{scope.row.cargo_info.aim_prov}}/{{scope.row.cargo_info.aim_city}}/{{scope.row.cargo_info.aim_county}}/{{scope.row.cargo_info.aim_other.addr_info?scope.row.cargo_info.aim_other.addr_info:'无'}}</div>
						<div class="tab_line_item">发车日期:{{scope.row.cargo_info.case_other.start_time}}{{(scope.row.cargo_info.case_other.start_time_is_check_text)}}</div>
					</template>
				</el-table-column>
				<el-table-column label="名称/重量/体积" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.cargo_info.cargo_type}}/{{scope.row.cargo_info.cargo_name}}</div>
						<div class="tab_line_item">{{scope.row.cargo_info.cargo_weight==0?"无重量":scope.row.cargo_info.cargo_weight+"吨"}}/{{scope.row.cargo_info.cargo_volume==0?"无体积":scope.row.cargo_info.cargo_volume+"方"}}</div>
						<div class="tab_line_item">距离:{{scope.row.cargo_info.distance}}KM</div>
					</template>
				</el-table-column>
				<el-table-column label="运费/支付状态" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.cost_info.freight_total}}元</div>
						<div class="tab_line_item">{{scope.row.settlement_type_text}}/{{scope.row.is_need_return_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="货主信息" width="160px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.shipper_info.name}}/{{scope.row.shipper_info.tel}}</div>
						<div class="tab_line_item">{{scope.row.shipper_company_info.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="收款人/司机/货车信息" width="180px">
					<template slot-scope="scope">
						<div class="tab_line_item">收款人:{{scope.row.truck_owner_info.name}}/{{scope.row.truck_owner_info.tel}}</div>
						<div class="tab_line_item">司机:{{scope.row.driver_info.name}}/{{scope.row.driver_info.tel}}</div>
						<div class="tab_line_item">{{scope.row.truck_info.truck_plate_num}}/{{scope.row.truck_info.truck_type_name_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作网点" width="140px">
					<template slot-scope="scope">
						<div class="tab_line_item">姓名:{{scope.row.outlets_info.name?scope.row.outlets_info.name:'无'}}</div>
						<div class="tab_line_item">电话:{{scope.row.outlets_info.tel?scope.row.outlets_info.tel:'无'}}</div>
						<div class="tab_line_item">网点:{{scope.row.outlets_info.outlets_name?scope.row.outlets_info.outlets_name:'无'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="运单状态/时间信息" width="172px">
					<template slot-scope="scope">
						<div class="tab_line_item">(状态){{scope.row.tord_status_text}}/{{scope.row.end_type_text}}</div>
						<div class="tab_line_item">(添加){{scope.row.tord_creat_time_text}}</div>
						<div class="tab_line_item">(发车){{scope.row.tord_start_time_text}}</div>
						<div class="tab_line_item">(到货){{scope.row.tord_arrived_time_text}}</div>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="90">
					<template slot-scope="scope">
						<el-button v-if="scope.row.end_type==1" @click="tord_upl_open(scope.row)" size="mini" type="text">上报</el-button>
						<el-button @click="voucher_show(scope.row)" size="mini" type="text">凭证</el-button>
						<el-button @click="tord_info(scope.row)" size="mini" type="text">详情</el-button>
						<el-button @click="truck_trajectory_open(scope.row)" size="mini" type="text">轨迹</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>

		<!-- 运单修改界面 -->
		<!-- <tordEdit :tord_info="edit.tord_info" :is_show="edit.is_show" @sub="tord_edit_sub"></tordEdit> -->

		<!-- 凭证界面 -->
		<voucher :is_show="voucher.is_show" :voucher_info="voucher.voucher_info"></voucher>

		<!-- 货车轨迹 -->
		<trucktTrajectory 
			v-if="trajectory.is_show" 
			:case_county="trajectory.case_county" 
			:aim_county="trajectory.aim_county" 
			:start_time="trajectory.start_time" 
			:end_time="trajectory.end_time" 
			:truck_plate_num="trajectory.truck_plate_num" 
			@close="truck_trajectory_close" 
		></trucktTrajectory>

		<!-- 上报记录 -->
		<uplList 
			:is_show="upl_list.is_show" 
			:tord_from="upl_list.tord_from" 
			:tord_info="upl_list.tord_info" 
		></uplList>

	</div>
</template>

<script>
	import {mapState} from 'vuex'
	import voucher from '../../voucher/voucher_after_end.vue'
	import uplList from '../upl/upl_list.vue'
	import trucktTrajectory from '@/components/map/amap/trajectory.vue'
	export default {
		components:{
			voucher,
			uplList,
			trucktTrajectory
		},
		data() {
			return {

				//搜索条件参数
				ser_para:{
					
					outlets_list:[],//网点列表
				},

				//搜索条件
				form: {				
					truck_tord_num:'',//货源编号
					out_trade_num:'',//上游编号
					group1_num:'',//组别编号
					shipper_tel:'',//货主手机号
					shipper_user_name:'',//货主姓名
					shipper_company_name:'',//货主公司名称
					truck_owner_tel:'',//收款人手机号
					truck_owner_name:'',//收款人姓名
					driver_tel:'',//司机手机号
					driver_name:'',//司机姓名
					truck_plate_num:'',//车牌号
					upl_aim:'',//运单上报目标
					upl_status:'',//上报状态
					settlement_type:'',//结算方式(1:按吨数,2:按方数,3:按趟)
					is_need_return:'',//是否回程(1:需要,2:不需要)
					outlets_num:'',//网点编号
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//凭证相关
				voucher:{
					is_show:0,
					truck_tord_num:'',
					voucher_info:[],
				},

				//运单上报记录
				upl_list:{
					is_show:0,
					tord_from:'end',
					tord_info:{}
				},

				//货车轨迹
				trajectory:{
					is_show:false,
					case_county:null,
					aim_county:null,
					start_time:null,
					end_time:null,
					truck_plate_num:null,
				}
			}
		},
		created() {

			//读取网点列表
			this.get_outlets_list();
			
			//读取货源
			this.get_page_data()
		},
		computed:{
			...mapState(['logink_aim_list'])
		},
		methods: {

			//运输轨迹
			truck_trajectory_open(item){
				this.trajectory.case_county=item.cargo_info.case_county;
				this.trajectory.aim_county=item.cargo_info.aim_county;
				this.trajectory.start_time=parseInt(item.tord_start_time);
				this.trajectory.end_time=parseInt(item.tord_arrived_time);
				this.trajectory.truck_plate_num=item.truck_plate_num;
				this.trajectory.is_show=true;
			},
			truck_trajectory_close(){
				this.trajectory.is_show=false;
				this.trajectory.case_county=null;
				this.trajectory.aim_county=null;
				this.trajectory.start_time=null;
				this.trajectory.end_time=null;
				this.trajectory.truck_plate_num=null;
			},
			
			//上报记录
			tord_upl_open(item){
				this.upl_list.tord_info={
					truck_tord_num:item.truck_tord_num,
					truck_plate_num:item.truck_plate_num,
					upl_status:item.upl_status,
					start_time:item.tord_start_time,
					arrived_time:item.tord_arrived_time,
					case_code:item.cargo_info.case_other.county_code,
					aim_code:item.cargo_info.aim_other.county_code,
				}
				this.upl_list.is_show++
			},

			//凭证相关操作
			voucher_show(item){//显示凭证
				this.voucher.is_show++;
				this.voucher.voucher_info=item.voucher_info
			},

			//运单详情
			tord_info(item){

			},
			
			//清空查询条件
			ser_para_clear(){
				this.form={
					truck_tord_num:'',//货源编号
					out_trade_num:'',//上游编号
					group1_num:'',//组别编号
					shipper_tel:'',//货主手机号
					shipper_user_name:'',//货主姓名
					shipper_company_name:'',//货主公司名称
					truck_owner_tel:'',//收款人手机号
					truck_owner_name:'',//收款人姓名
					driver_tel:'',//司机手机号
					driver_name:'',//司机姓名
					truck_plate_num:'',//车牌号
					settlement_type:'',//结算方式(1:按吨数,2:按方数,3:按趟)
					is_need_return:'',//是否回程(1:需要,2:不需要)
					outlets_num:'',//网点编号
				}
				this.get_page_data()
			},

			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_end_list_admin',
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//运单创建时间
							item.tord_creat_time_text=this.$my.other.totime(item.tord_creat_time);
							
							//运单发车时间
							if(item.tord_start_time>0){//已发车
								item.tord_start_time_text=this.$my.other.totime(item.tord_start_time);
							}else item.tord_start_time_text="未发车";
							
							//运单到达时间
							if(item.tord_arrived_time>0){//已到货
								item.tord_arrived_time_text=this.$my.other.totime(item.tord_arrived_time);
							}else item.tord_arrived_time_text="未到货";

							//运单结束时间
							item.tord_end_time_text=this.$my.other.totime(item.tord_end_time);

							//完结类型(1:完结运单,2:取消运单)
							switch(item.end_type){
								case '1':item.end_type_text="完结运单";break;
								case '2':item.end_type_text="取消运单";break;
							}

							//运单状态
							switch(item.tord_status){
								case '1':item.tord_status_text="待发车";break;
								case '2':item.tord_status_text="运输中";break;
								case '3':item.tord_status_text="已到货";break;
							}

							//结算方式
							switch(item.settlement_type){
								case '1':item.settlement_type_text="按吨数";break;
								case '2':item.settlement_type_text="按方数";break;
								case '3':item.settlement_type_text="按趟";break;
							}
							
							//是否回程
							switch(item.is_need_return){
								case '1':item.is_need_return_text="需要回程";break;
								case '2':item.is_need_return_text="不需要回程";break;
							}

							//运费支付状态
							switch(item.freight_payed_status){
								case '1':item.freight_payed_status_text="未支付";break;
								case '2':item.freight_payed_status_text="部分支付";break;
								case '3':item.freight_payed_status_text="支付完毕";break;
								default :item.freight_payed_status_text="未支付";break;
							}

							//货源详情
							item.cargo_info=JSON.parse(item.cargo_info)

							//发车时间
							if(!item.cargo_info.case_other.start_time_1 && item.cargo_info.case_other.start_time_2){//只有止点

								item.cargo_info.case_other.start_time=this.$my.other.todate(item.cargo_info.case_other.start_time_2)+"以前";

							}else if(item.cargo_info.case_other.start_time_1 && !item.cargo_info.case_other.start_time_2){//只有起点

								item.cargo_info.case_other.start_time=this.$my.other.todate(item.cargo_info.case_other.start_time_1)+"以后";

							}else if(item.cargo_info.case_other.start_time_1 && item.cargo_info.case_other.start_time_2){//都有

								item.cargo_info.case_other.start_time=this.$my.other.todate(item.cargo_info.case_other.start_time_1)+" ~ "+this.$my.other.todate(item.cargo_info.case_other.start_time_2);

							}else if(!item.cargo_info.case_other.start_time_1 && !item.cargo_info.case_other.start_time_2){//都没有
								
								item.cargo_info.case_other.start_time="未设置";
							}

							//发车时间是否强制校验
							switch(item.cargo_info.case_other.start_time_is_check){
								case '1':item.cargo_info.case_other.start_time_is_check_text="(强制)";break;
								case '2':item.cargo_info.case_other.start_time_is_check_text="(不强制)";break;
							}
							if(item.cargo_info.case_other.start_time=="未设置"){//如果未设置发车时间,则不存在强制不强制
								item.cargo_info.case_other.start_time_is_check_text=""
							}

							//费用信息
							item.cost_info=JSON.parse(item.cost_info)

							//货主详情
							item.shipper_info=JSON.parse(item.shipper_info)

							//货主公司详情
							item.shipper_company_info=JSON.parse(item.shipper_company_info)

							//网点信息
							item.outlets_info=JSON.parse(item.outlets_info)

							//网点用户信息
							item.outlets_user_info=JSON.parse(item.outlets_user_info)

							//收款人详情
							item.truck_owner_info=JSON.parse(item.truck_owner_info)

							//司机数据
							item.driver_info=JSON.parse(item.driver_info)

							//货车详情
							item.truck_info=JSON.parse(item.truck_info)

							//货车类型
							item.truck_info.truck_type_name_text=item.truck_info.truck_type_name+'/'+item.truck_info.truck_type_name2

							//凭证列表
							item.voucher_info=JSON.parse(item.voucher_info)
						}

						//渲染
						this.list.data=data.list
					}
				})
			},

			//读取网点列表
			get_outlets_list(){
				
				//调用接口
				this.$my.net.req({
					data:{
						mod:'truck_business_outlets',
						ctr:'outlets_list_admin',
						num:1000
					},
					callback:(data)=>{
						
						//渲染
						this.ser_para.outlets_list=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 190px);
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>